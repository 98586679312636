import styled from '@emotion/styled'
import { useState } from 'react'
import { AppStore } from 'stylewhere/shared'

export const WorkstationEmulationInfoBox = () => {
  const [visible, setVisible] = useState<boolean>(AppStore.emulation)

  return visible ? (
    <WorkstationEmulationBox onClick={() => setVisible(false)}>{`[ EMULATION ENABLED ]`}</WorkstationEmulationBox>
  ) : null
}

const WorkstationEmulationBox = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 4px;
  border-radius: 8px 0px 0px 0px;
  background-color: lightBlue;
  cursor: pointer;
`
