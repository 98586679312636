import { format } from 'date-fns'
import { get as _get, set as _set } from 'lodash'
import { StaticContext } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { api, BaseResource, ResourcePage, responseErrorCheck } from 'stylewhere/api'
import i18n, { T, __ } from 'stylewhere/i18n'
import { AppStore } from 'stylewhere/shared'
import { showToast, showToastError, getAutocompletePostData } from 'stylewhere/utils'
import { parse, stringify } from 'qs'

export type FormSchemaRouteProps<Props = {}, State = {}> = RouteComponentProps<
  //@ts-ignore
  Props,
  StaticContext,
  State & { formData?: FormSchemaData }
>

type Localizable = string | { en: string; it: string }

interface Validation {
  regexp: string
  errors: {
    //key is the language es: en, it
    [key: string]: string
  }
}

interface BaseSchemaDependency {
  is: string
  whenField: string // campo name
  condition: string
}

interface SchemaDependencySimple {
  is: 'disabled' | 'hidden' | 'enabled' | 'visible' | 'required' | 'optional' | 'emptied'
}

interface SchemaDependencyFilledWithValue {
  is: 'filledWithValue'
  filledWithValue: any
}

interface SchemaDependencyEndpointReplaced {
  is: 'endpointReplaced'
  endpointReplaced: { [key: string]: string }
}

interface SchemaDependencyIsEmptyFilled {
  condition: 'isEmpty' | 'isFilled'
  conditionValue?: never
}

interface SchemaDependencyHasValue {
  condition: 'hasValue'
  conditionValue: any
}

type AutocompleteSchemaDependency = BaseSchemaDependency &
  (SchemaDependencyIsEmptyFilled | SchemaDependencyHasValue) &
  (SchemaDependencySimple | SchemaDependencyFilledWithValue | SchemaDependencyEndpointReplaced)

type SchemaDependency =
  | (BaseSchemaDependency &
      (SchemaDependencyIsEmptyFilled | SchemaDependencyHasValue) &
      (SchemaDependencySimple | SchemaDependencyFilledWithValue))
  | AutocompleteSchemaDependency

interface AutocompleteSchemeFields {
  name: string
  field: string
}

interface AutocompleteActionPayload {
  operationId: string
  attributes?: AutocompleteActionPayloadAttribute[]
}

interface AutocompleteActionPayloadAttribute {
  field: string
  value?: string
  dynamicValue?: string
}

interface AutocompleteActionField {
  code: string
  payload: AutocompleteActionPayload
}

export interface AutocompleteFormSchemaField extends Basefield {
  type: 'autocomplete'
  optionLabel?: 'description' | string

  endpoint: string
  method?: 'POST' | 'GET'
  /** Campo usato dalla dipendenza endpointReplaced */
  rawEndpoint?: string
  dependencies?: AutocompleteSchemaDependency[]
  /** Campo usato al posto di defaultValue quando la risorsa default deve essere caricata con un endpoint custom */
  defaultValueEndpoint?: string
  /** Usato in fetchAutocompleteDefaultValues come chiave di cache */
  defaultValueCacheKey?: string
  multiple?: boolean
  dynamicParams?: any
  autoSelectDefault?: string
}

export interface CheckboxFormSchemaField extends Basefield {
  type: 'checkbox'
  onValue?: string | number | boolean
  offValue?: string | number | boolean
}

export type DateFormSchemaField = Basefield & {
  type: 'date'
  time?: boolean
  mindate?: string
  maxdate?: string
  dateFormat?: string
  useTimezone?: boolean
} & (
    | {
        range: false
      }
    | {
        range: true
        startDatePath: string
        endDatePath: string
      }
  )

export interface NumberFormSchemaField extends Basefield {
  type: 'number'
  min?: number
  max?: number
  step?: number
  icon?: string
  iconWidth?: number
  iconHeight?: number
  autoFocus?: boolean
}

export interface TextFormSchemaField extends Basefield {
  type: 'text' | 'textarea' | 'password'
  minlength?: number
  maxlength?: number
  icon?: string
  iconWidth?: number
  iconHeight?: number
  autoFocus?: boolean
  upperCase?: boolean
}

export interface SelectFormSchemaField extends Basefield {
  type: 'select'
  options: { label: string; value: string }[]
  multiple?: boolean
}

/**
 * I campi di tipo "value" hanno un valore fisso non modificabile (supporta i token).
 * Sono visualizzati nella form come campi di testo readonly
 */
export interface ValueFormSchemaField extends Basefield {
  type: 'value'
  defaultValue: string
}

/**
 * I campi di tipo "hidden" e possono avere un valore di default
 * Non sono visualizzati nella form
 */
export interface HiddenFormSchemaField extends Basefield {
  type: 'hidden'
  defaultValue?: string
}

interface TextInputFormat {
  minLength?: number
  prefix?: boolean
  character?: string
  dynamicField?: string
  separator?: string
  regex?: string
}

interface Basefield {
  name: string
  label: string
  secondaryLabel?: string
  defaultValue?: any
  required?: boolean
  onChange?: (value: any, formData: any, setFormData: (newFormData: any) => void) => void
  hide?: boolean
  focus?: boolean
  disabled?: boolean
  readOnly?: boolean | 'start' | 'read'
  confirmValuePath?: string | false // False significa che il valore non deve essere inviato nella confirm
  dependencies?: SchemaDependency[]
  // L'opzione customRender è usabile solo via codice, ad esempio in una extension (formSchema):
  // myField.customRender = (props) => <MyComponent {...props} />
  customRender?: (props: any) => JSX.Element
  autocompleteFields?: AutocompleteSchemeFields[]
  autocheckEndpoint?: string
  autocheckEndpointField?: string
  autocheckEndpointBlockIfEmpty?: boolean
  autocheckFormSubmit?: boolean
  directSubmit?: boolean
  storage?: boolean
  optionSecondaryLabel?: 'code' | string
  concatSecondaryLabel?: boolean
  submitOnEnter?: boolean
  focusOnField?: string
  regExp?: string
  regExpError?: string
  disableChange?: boolean
  addEmptyField?: boolean
  //only date
  currentDateAsDefault?: boolean

  format?: TextInputFormat
  action?: AutocompleteActionField
  searchDisabled?: boolean
  copyValueFrom?: string

  isProductCode?: boolean

  filterKey?: string
  validation?: Validation
}

export interface RawSchemaField {
  // Base
  type: 'autocomplete' | 'checkbox' | 'date' | 'number' | 'text' | 'textarea' | 'password' | 'select' | 'hidden'
  name: string
  label: Localizable
  secondaryLabel?: Localizable
  defaultValue?: any
  required?: boolean
  onChange?: string
  hide?: boolean
  focus?: boolean
  disabled?: boolean
  readOnly?: boolean | 'start' | 'read'
  confirmValuePath?: string | false
  dependencies?: SchemaDependency[]
  storage?: boolean
  // autocomplete
  optionLabel?: 'description' | string
  optionSecondaryLabel?: 'code' | string
  concatSecondaryLabel?: boolean
  endpoint?: string
  method?: 'POST' | 'GET'
  dynamicParams?: any
  defaultValueEndpoint?: string
  action?: AutocompleteActionField
  // select
  // Le opzioni delle select hanno due formati possibili:
  // - Value + label localizzabile: { label, value }[]
  // - Solo value: string[]
  options?: ({ label: Localizable; value: string | number | boolean } | string)[]
  multiple?: boolean
  // text
  minlength?: number
  maxlength?: number
  icon?: string
  iconWidth?: number
  iconHeight?: number
  // number
  min?: number
  max?: number
  step?: number
  // checkbox
  onValue?: string | number | true
  offValue?: string | number | false
  // date
  time?: boolean
  range?: boolean
  mindate?: string
  maxdate?: string
  dateFormat?: string
  useTimezone?: boolean
  currentDateAsDefault?: boolean
  startDatePath?: string
  endDatePath?: string
  visible?: boolean
  // value
  value?: any

  autocompleteFields?: AutocompleteSchemeFields[]

  autocheckEndpoint?: string
  autocheckEndpointField?: string
  autocheckEndpointBlockIfEmpty?: boolean
  autocheckFormSubmit?: boolean
  directSubmit?: boolean
  submitOnEnter?: boolean
  focusOnField?: string

  autoFocus?: boolean
  regExp?: string
  regExpError?: Localizable

  disableChange?: boolean
  addEmptyField?: boolean
  autoSelectDefault?: string

  format?: TextInputFormat
  searchDisabled?: boolean
  copyValueFrom?: string

  isProductCode?: boolean
  upperCase?: boolean

  filterKey?: string
  validation?: Validation
}

export type FormSchemaField =
  | AutocompleteFormSchemaField
  | CheckboxFormSchemaField
  | DateFormSchemaField
  | NumberFormSchemaField
  | TextFormSchemaField
  | SelectFormSchemaField
  | ValueFormSchemaField
  | HiddenFormSchemaField
export type FormSchema = FormSchemaField[]
export type FormSchemaFieldData = string | boolean | number | undefined | Record<PropertyKey, string | boolean | number>
export type FormSchemaData<D extends Record<PropertyKey, FormSchemaFieldData> = any> = D

function translateLabel(label: Localizable): string {
  if (typeof label === 'string') {
    return label
  }
  if (typeof label[i18n.language] === undefined) {
    throw new Error(`Missing ${i18n.language} translation`)
  }
  return label[i18n.language]
}

const autocompleteDefaultValuesCache: Record<string, BaseResource | false> = {}

let fetchAutocompleteInProgress = false

/** Converte i defaultValues dei campi autocomplete da stringhe di id a oggetti */
export async function fetchAutocompleteDefaultValues(schema: FormSchema, skipAutoselectFormDefaultValues?: boolean) {
  if (fetchAutocompleteInProgress) return schema
  fetchAutocompleteInProgress = true

  const fields = schema.filter(
    (field): field is AutocompleteFormSchemaField =>
      field.type === 'autocomplete' &&
      (!!field.endpoint || !!field.defaultValueEndpoint) &&
      !skipAutoselectFormDefaultValues &&
      !field.dependencies &&
      !field.hide &&
      !!field.required
    // && !field.readOnly // mettendo questo negli Schema con readOnly: true non visualizziamo il valore di Default ref. BCS5-814
  )

  for (const field of fields) {
    const { name, defaultValue, label, endpoint, defaultValueEndpoint } = field
    const cacheKey = field.defaultValueCacheKey ?? uuidv4()
    field.defaultValueCacheKey = cacheKey

    // if (autocompleteDefaultValuesCache[cacheKey]) continue

    //create data for search defaultValue
    let paramsSearch: any = {}
    if (typeof defaultValue === 'string' && defaultValue !== '') {
      if (Object.keys(DefaultArgsParams()).includes(defaultValue)) paramsSearch = { ids: setArgsParams(defaultValue) }
      else if (name.endsWith('Code')) paramsSearch = { equalCodes: setArgsParams(defaultValue) }
      else if (name.endsWith('Id')) paramsSearch = { ids: setArgsParams(defaultValue) }
    }

    let result

    try {
      // eslint-disable-next-line no-await-in-loop
      const finalEndpoint = addEnabledField(setArgsParams(defaultValueEndpoint ?? endpoint))
      if (field.method === 'POST') {
        result = await api.post<ResourcePage<BaseResource> | BaseResource[]>(
          defaultValueEndpoint ?? endpoint,
          getAutocompletePostData(finalEndpoint, paramsSearch)
        )
      } else {
        result = await api.get<ResourcePage<BaseResource>>(finalEndpoint, paramsSearch)
      }
      responseErrorCheck(result)
      // eslint-disable-next-line no-empty
    } catch (e) {
      showToastError(e)
    }

    if (result.ok) {
      if (result.data?.content[0]) {
        if (result.data.content.length === 1) {
          autocompleteDefaultValuesCache[cacheKey] = result.data.content[0]
        } else if (field.autoSelectDefault && result.data.content.length > 0) {
          let c = 0
          let found = false
          while (c < result.data.content.length && !found) {
            if (result.data.content[c][field.autoSelectDefault ?? '']) {
              found = true
              autocompleteDefaultValuesCache[cacheKey] = result.data.content[c]
            }
            c++
          }
        } else {
          autocompleteDefaultValuesCache[cacheKey] = false
          // showToast({
          //   status: 'error',
          //   title: __(T.error.error),
          //   description: `Error in autocomplete default values cache for ${field.defaultValue} ${field.name}`,
          // })
        }
      } else {
        const error = field.required && field.readOnly
        autocompleteDefaultValuesCache[cacheKey] = false
        showToast({
          title: __(error ? T.error.error : T.misc.warning),
          description: defaultValueEndpoint
            ? __(T.error.field_invalid_default_from_endpoint, {
                field: label,
              })
            : __(T.error.field_invalid_default, {
                defaultValue,
                field: label,
              }),
          status: error ? 'error' : 'warning',
        })
      }

      if (autocompleteDefaultValuesCache[cacheKey]) {
        field.defaultValue = autocompleteDefaultValuesCache[cacheKey]
      }
    }
  }
  fetchAutocompleteInProgress = false
  return schema
}

export function jsonToFormSchema(raw: RawSchemaField[]): FormSchema {
  return raw.map((field) => {
    const baseField: Basefield = {
      name: field.name,
      label: translateLabel(field.label),
      secondaryLabel: field.secondaryLabel && translateLabel(field.secondaryLabel),
      required: field.required ?? true,
      defaultValue: field.defaultValue,
      hide: field.hide ?? false,
      focus: field.focus ?? false,
      disabled: field.disabled ?? false,
      readOnly: field.readOnly ?? false,
      // eslint-disable-next-line import/no-dynamic-require
      onChange: field.onChange && require(`stylewhere/config/${field.onChange}`),
      confirmValuePath: field.confirmValuePath,
      dependencies: field.dependencies,
      storage: field.storage || false,
      disableChange: field.disableChange ?? false,
      addEmptyField: field.addEmptyField ?? false,
      copyValueFrom: field.copyValueFrom ?? undefined,
      isProductCode: field.isProductCode || false,
      filterKey: field.filterKey ?? '',
      validation: field.validation,
    }

    let schemaField: FormSchemaField
    // By type
    if (field.type === 'autocomplete') {
      if (!field.endpoint) throw new Error('Missing option endpoint')
      schemaField = {
        type: 'autocomplete',
        optionLabel: field.optionLabel ?? 'description',
        optionSecondaryLabel: field.optionSecondaryLabel,
        concatSecondaryLabel: field.concatSecondaryLabel,
        endpoint: field.endpoint,
        dynamicParams: field.dynamicParams,
        defaultValueEndpoint: field.defaultValueEndpoint,
        multiple: field.multiple ?? false,
        autocompleteFields: field.autocompleteFields || [],
        autocheckFormSubmit: field.autocheckFormSubmit ?? false,
        directSubmit: field.directSubmit ?? false,
        submitOnEnter: field.submitOnEnter ?? false,
        focusOnField: field.focusOnField ?? undefined,
        autoSelectDefault: field.autoSelectDefault ?? '',
        action: field.action,
        searchDisabled: field.searchDisabled ?? false,
        method: field.method ?? 'GET',
        ...baseField,
      }
    } else if (field.type === 'checkbox') {
      schemaField = {
        type: 'checkbox',
        onValue: field.onValue ?? true,
        offValue: field.offValue ?? false,
        ...baseField,
        required: field.required ?? false,
        defaultValue: field.defaultValue ?? false,
      }
    } else if (field.type === 'date') {
      if (field.range) {
        schemaField = {
          type: 'date',
          time: field.time ?? false,
          range: true,
          mindate: field.mindate,
          maxdate: field.maxdate,
          dateFormat: field.dateFormat || 'dd/MM/yyyy',
          useTimezone: field.useTimezone ?? false,
          currentDateAsDefault: field.currentDateAsDefault ?? false,
          startDatePath: field.startDatePath ?? `${field.name}.from`,
          endDatePath: field.endDatePath ?? `${field.name}.to`,
          ...baseField,
        }
      } else {
        schemaField = {
          type: 'date',
          time: field.time ?? false,
          range: false,
          mindate: field.mindate,
          maxdate: field.maxdate,
          dateFormat: field.dateFormat || 'dd/MM/yyyy',
          useTimezone: field.useTimezone ?? false,
          currentDateAsDefault: field.currentDateAsDefault ?? false,
          ...baseField,
        }
      }
    } else if (field.type === 'number') {
      schemaField = {
        type: 'number',
        min: field.min,
        max: field.max,
        step: field.step,
        icon: field.icon,
        iconWidth: field.iconWidth || 24,
        iconHeight: field.iconHeight || 24,
        autoFocus: field.autoFocus || false,
        focusOnField: field.focusOnField ?? undefined,
        regExp: field.regExp ?? undefined,
        regExpError: field.regExpError ? translateLabel(field.regExpError) : undefined,
        ...baseField,
      }
    } else if (field.type === 'text' || field.type === 'textarea' || field.type === 'password') {
      schemaField = {
        type: field.type,
        minlength: field.minlength,
        maxlength: field.maxlength,
        icon: field.icon,
        iconWidth: field.iconWidth || 24,
        iconHeight: field.iconHeight || 24,
        autocheckEndpoint: field.autocheckEndpoint,
        autocheckEndpointField: field.autocheckEndpointField,
        autocheckEndpointBlockIfEmpty: field.autocheckEndpointBlockIfEmpty ?? false,
        autocheckFormSubmit: field.autocheckFormSubmit,
        submitOnEnter: field.submitOnEnter ?? false,
        focusOnField: field.focusOnField ?? undefined,
        autocompleteFields: field.autocompleteFields || [],
        autoFocus: field.autoFocus || false,
        regExp: field.regExp ?? undefined,
        regExpError: field.regExpError ? translateLabel(field.regExpError) : undefined,
        format: field.format,
        upperCase: field.upperCase,
        ...baseField,
      }
    } else if (field.type === 'select') {
      if (field.options === undefined) {
        throw new Error('Missing option options')
      }
      schemaField = {
        type: 'select',
        options: field.options.map((option) =>
          typeof option === 'string'
            ? {
                label: option,
                value: option,
              }
            : {
                label: translateLabel(option.label),
                value: option.value.toString(),
              }
        ),
        multiple: field.multiple ?? false,
        ...baseField,
      }
    } else if (field.type === 'hidden') {
      schemaField = {
        type: 'hidden',
        defaultValue: field.defaultValue,
        format: field.format,
        ...baseField,
      }
    } else if (field.type === 'value') {
      schemaField = {
        type: 'value',
        defaultValue: field.defaultValue,
        ...baseField,
      }
    } else {
      throw new Error(`Unsupported field type ${field.type}`)
    }

    return schemaField
  })
}

export function getFieldValue(formData: FormSchemaData, field: FormSchemaField) {
  return _get(formData, field.name)
}

/** Imposta value in formData al percorso di field.name (con dot notation) */
export function setFieldValue(value: any, formData: FormSchemaData, field: FormSchemaField) {
  _set(formData, field.name, value)
}

export function formatFieldValue(formData: FormSchemaData, field: FormSchemaField): string | undefined {
  const value = getFieldValue(formData, field)
  if (value === null || value === undefined || value === '') return undefined

  if (field.type === 'date') {
    const formatDate = (date) => date && format(new Date(date), field.dateFormat || 'dd/MM/yyyy')
    if (field.range) {
      const startDate = formatDate(value[field.startDatePath])
      const endDate = formatDate(value[field.endDatePath])
      return startDate && endDate ? `${startDate} - ${endDate}` : `${startDate}${endDate}`
    }
    return formatDate(value)
  }

  if (field.type === 'autocomplete') {
    let label: string
    if (field.optionLabel) {
      label = value[field.optionLabel]
      if (!label && value.code) {
        label = value.code
      }
      if (field.concatSecondaryLabel && field.optionSecondaryLabel) {
        label = label + ' - ' + value[field.optionSecondaryLabel]
      }
    } else if (field.optionSecondaryLabel) {
      label = value[field.optionSecondaryLabel]
    } else {
      label = ((value as any).description || (value as any).code || (value as any).id) ?? ''
    }
    return label
  }

  if (field.type === 'checkbox') {
    if (value === true) {
      return __(T.misc.yes)
    }
    if (value === false) {
      return __(T.misc.no)
    }
  }

  if (typeof value === 'number') return value.toString()
  if (typeof value === 'boolean') throw new Error('Invalid value')

  return value
}

export function getFieldConfirmValue(formData: FormSchemaData, field: FormSchemaField) {
  if (field.confirmValuePath === false) return undefined
  const object = getFieldValue(formData, field)
  if (field.confirmValuePath) {
    /*let value = _get(object, field.confirmValuePath)
    if (field.concatSecondaryLabel && field.optionSecondaryLabel) {
      value = value + ' - ' + _get(object, field.optionSecondaryLabel)
    }
    return value*/
    return _get(object, field.confirmValuePath)
  }
  return object
}

export function getDataFromSchema<DataType extends Record<PropertyKey, any>>(
  formData: FormSchemaData,
  formSchema: FormSchema
) {
  const data: DataType = JSON.parse(JSON.stringify(formData))
  Object.values(formSchema ?? {}).forEach((fs) => _set(data, fs.name, getFieldConfirmValue(formData, fs)))
  return data
}

/** Token per gli endpoint e i defaultValues. Possono essere stringhe o oggetti  */
const DefaultArgsParams = (endpoint?: string) => {
  /** Sostituisce il place della postazione attiva */
  const result = { '{placeId}': AppStore?.defaultWorkstation?.placeId ?? '' }
  if (endpoint && endpoint.includes('{place.')) {
    const workStationPlace = AppStore.loggedUser?.userPlaces.find(
      (up) => up.id === AppStore.defaultWorkstation?.placeId
    )
    if (workStationPlace) {
      const matches = endpoint.match(/{place\..*?}/g)
      if (matches) {
        matches.forEach((match) => {
          const key = match.replace('{place.', '').replace('}', '')
          const value = _get(workStationPlace, key)
          if (value !== undefined) result[match] = value
        })
      }
    }
  }
  return result
}

/** Sostituisce i token in un endpoint con i valori di DefaultArgsParams  */
export function setArgsParams(endpoint: string) {
  const defaultArgsParams = DefaultArgsParams(endpoint)
  Object.keys(defaultArgsParams).forEach((key) => {
    endpoint = endpoint.replaceAll(key, defaultArgsParams[key])
  })
  return endpoint
}

export function checkRequiredField(formData: FormSchemaData, formSchema: FormSchema): boolean {
  try {
    let checked = true
    Object.values(formSchema ?? {})
      .filter((fs) => fs.required)
      .forEach((fs) => {
        if (getFieldConfirmValue(formData, fs) === undefined) checked = false
      })
    return checked
  } catch (error) {
    return false
  }
}

const endpointsWithEnabledField = ['/zones', '/places', '/workstation']

export function addEnabledField(endpoint: string) {
  if (!endpointsWithEnabledField.find((search) => endpoint.includes(search))) {
    return endpoint
  }

  let queryPartStr = ''
  let queryPart = parse('')
  let basePart = ''
  const urlParts = endpoint.split('?')
  if (urlParts.length > 1) {
    queryPartStr = urlParts.slice(1).join('?')
    basePart = urlParts[0]
  } else {
    basePart = endpoint
  }

  if (queryPartStr) {
    queryPart = parse(queryPartStr)
  }

  if (!('enabled' in queryPart)) {
    queryPart.enabled = 'true'
  }

  const newEndpoint = `${basePart}?${stringify(queryPart)}`

  return newEndpoint
}
